import React, { useState, useRef } from 'react';
import RichText from './richtext';
import { ChevronUp } from './icons';

const AccordionItem = (props) => {
  const [isActive, setIsActive] = useState(parseInt(props.index) === 0 ? true : false);
  const panelInner = useRef();
  let panelHeight = 'auto';

  if (props.id === '50a07308-a10e-5f81-b31f-23c728058a42') {
    return '';
  }

  if (panelInner && panelInner.current && panelInner.current.clientHeight) {
    panelHeight = panelInner.current.clientHeight;
  }

  return (
    <div className="accordion__item">
      <button
        className={`title ${isActive ? 'active' : ''}`}
        onClick={() => setIsActive(!isActive)}
      >
        <span>{props.question}</span>
        <ChevronUp />
      </button>
      <div
        className={`panel ${isActive ? 'active' : ''}`}
        style={{ height: isActive ? panelHeight : '0px' }}
      >
        <div ref={panelInner}>
          <RichText {...props.answer} />
        </div>
      </div>
    </div>
  );
};

export default (props) => {
  const items = [];

  for (const item in props) {
    items.push(<AccordionItem key={item} {...props[item]} index={item} />);
  }

  return <div className="accordion">{items}</div>;
};

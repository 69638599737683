import React from 'react';
import Accordion from './accordion';

export default (props) => {
  if (Object.entries(props).length < 1) {
    return '';
  } else if (
    Object.entries(props).length === 1 &&
    props['0'].id === '50a07308-a10e-5f81-b31f-23c728058a42'
  ) {
    return '';
  }

  return (
    <section className="q-and-a bg-light-grey">
      <div className="inner inner--small">
        <h4>Questions and Answers</h4>
        <Accordion {...props} />
      </div>
    </section>
  );
};

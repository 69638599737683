import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';
import VideoSection from '../components/video-section';
import Form from '../components/form';
import PresentersSection from '../components/presenters-section';
import QAndASection from '../components/qanda-section';

class PageTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulPages;
    let deck = '';
    let headerImage = false;
    if (pageData.deck) {
      deck = pageData.deck.file.url;
    }
    if (pageData.headerImage) {
      headerImage = pageData.headerImage.resize;
    }

    return (
      <Layout location={this.props.location} title={pageData.title} linkToIndex={true}>
        <Hero title={pageData.title} introduction={pageData.introduction} image={headerImage} />
        <VideoSection url={pageData.youtubeUrl} />
        <Form
          slug={pageData.slug}
          deck={deck}
          formCheckboxText={pageData.formCheckboxText}
          formCopy={pageData.formCopy}
          formCopyHeading={pageData.formCopyHeading}
        />
        <PresentersSection presenters={pageData.presenters} />
        <QAndASection {...pageData.qAndAs} />
      </Layout>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query PageById($id: String!) {
    contentfulPages(id: { eq: $id }) {
      title
      slug
      headerImage {
        resize(width: 1920) {
          src
        }
      }
      introduction {
        json
      }
      youtubeUrl
      presenters {
        image {
          resize(height: 300, width: 300, resizingBehavior: FILL) {
            src
          }
          title
        }
        name
        friendlyName
        email
      }
      deck {
        file {
          url
        }
      }
      formCheckboxText
      formCopy {
        formCopy
      }
      formCopyHeading {
        formCopyHeading
      }
      qAndAs {
        id
        question
        answer {
          json
        }
      }
    }
  }
`;
